import axios from "axios";

export const https = () => {
  const token = localStorage.getItem("accessToken");
  const instance = axios.create({
    baseURL: process.env.VUE_APP_SIRIUS_URL,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return instance;
};
