<template>
  <div>
    <vs-card>
      <div v-if="errors.count() > 0">
        <fieldset class="fieldErrors">
          <legend>Atenção aos erros do formulário:</legend>
          <ul>
            <li
              v-for="(error, index) in errors.all()"
              :key="index"
              class="alert"
            >
              {{ error }}
            </li>
          </ul>
        </fieldset>
      </div>
      <form>
        <p>Nome da peça</p>
        <vs-input
          v-validate="'required'"
          name="pieceName"
          v-model="request.pieceName"
          class="w-full mb-6"
          data-vv-as="Nome da Peça"
        />
        <ul class="con-s">
          <li>
            <label for="hasSAPCode">Possui código SAP</label>
            <vs-switch v-model="request.hasSAPCode" id="hasSAPCode" />
          </li>
        </ul>
        <p v-if="request.hasSAPCode">Informar Código SAP</p>
        <vs-input
          v-validate="'required|digits:8'"
          name="SAPCode"
          v-mask="'########'"
          v-model="request.SAPCode"
          v-if="request.hasSAPCode"
          class="w-full mb-6"
          data-vv-as="Código SAP"
        />
        <p v-if="request.hasSAPCode">
          Valor SAP Unitário
          <money
            v-if="request.hasSAPCode"
            v-model="request.value"
            v-bind="request.value"
            class="w-full mb-6 select1"
          ></money>
        </p>
        <p v-if="!request.hasSAPCode">
          Valor estimado
          <money
            v-if="!request.hasSAPCode"
            v-model="request.estimatedValue"
            v-bind="request.estimatedValue"
            class="w-full mb-6 select1"
          ></money>
        </p>
        <p>
          Família
          <select
            name="family"
            v-validate="'required'"
            v-model="request.family"
            label="Família"
            class="w-full mb-6 select1"
            @change="handleSelectedItem()"
            data-vv-as="Família"
          >
            <option value="-1">Cadastrar nova (+)</option>
            <option
              :key="index"
              :value="item.id"
              v-for="(item, index) in familyOptions"
            >
              {{ item.name }}
            </option>
          </select>
        </p>
        <vs-button @click="submitProject()" class="mr-6"
          >Criar Requisição</vs-button
        >
      </form>
    </vs-card>
    <vs-popup title="Cadastrar nova família" :active.sync="showFamilyAddScreen">
      <form>
        <p>Nome da família</p>
        <vs-input
          name="newFamilyName"
          v-model="family.name"
          class="w-full mb-6"
          @keyup.enter="submitFamily()"
        />
        <vs-button @click="submitFamily()" class="mr-6">Cadastrar</vs-button>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import siriusAPI from "../services";
import { saveDataInStorage } from "../utils/storage";
import { notifyError, notifySuccess } from "../utils/helpers";

export default {
  data() {
    return {
      request: {
        pieceName: null,
        hasSAPCode: true,
        family: null,
        SAPCode: null,
        value: null,
        estimatedValue: null,
        requestingUser: null,
      },
      family: { name: null },
      familyOptions: [],
      error: "",
      showFamilyAddScreen: false,
    };
  },

  mounted() {
    this.loadFamilies();
  },

  methods: {
    resetRequest() {
      this.request = {
        pieceName: null,
        hasSAPCode: true,
        family: null,
        SAPCode: null,
        value: null,
        estimatedValue: null,
        requestingUser: null,
      };
    },

    async loadFamilies() {
      new siriusAPI()
        .getFamilies()
        .then((res) => {
          this.familyOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de famílias " + error
          );
        });
    },
    submitProject() {
      this.$validator.validate().then((valid) => {
        if (!valid) {
          notifyError(this, "Corrigir os erros apresentados.");
          return;
        } else {
          const payload = {
            sapValue: this.request.value,
            sapCode: this.request.SAPCode,
            name: this.request.pieceName,
            estimatedValue: this.request.estimatedValue,
            familyId: this.request.family,
          };
          new siriusAPI()
            .addRequest(payload)
            .then((res) => {
              this.resetRequest();
              notifySuccess(this, "Nova requisição gerada com sucesso!");
              saveDataInStorage({ request: res.data });
              this.$router.push("/admin/Order");
            })
            .catch((error) => {
              notifyError(
                this,
                "Erro ao tentar gerar uma nova requisição " + error
              );
            });
        }
      });
    },
    handleSelectedItem() {
      if (this.request.family == "-1") this.showFamilyAddScreen = true;
    },

    async submitFamily() {
      if (this.family.lenght == 0) {
        notifyError(this, "Preencha o nome da família!");
        return;
      }
      const family = this.family.toUpperCase();
      this.$vs.loading();
      await new siriusAPI()
        .addFamily(family)
        .then((res) => {
          this.$vs.loading.close();
          this.request.family = res.data.id;
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar cadastrar nova família: " + error);
        });
      await this.loadFamilies();
      this.showFamilyAddScreen = false;
    },
  },
};
</script>
<style scoped>
.radio-space {
  padding-right: 0.5em;
}
ul {
  margin-bottom: 0.8em;
}
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.alert {
  color: #f00;
}
.fieldErrors {
  padding: 1em;
  margin-bottom: 1em;
  border: 0.8px solid #bbb;
}
</style>
