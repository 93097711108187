import { https } from "./api";

export default class {
  constructor() {
    this.instance = https();
  }

  getFamilies = () => {
    return this.instance({
      method: "GET",
      url: "/families",
    });
  };
  addFamily(data) {
    return this.instance({
      method: "POST",
      url: "/families",
      data,
    });
  }
  getUnits() {
    return this.instance({
      method: "GET",
      url: "/units",
    });
  }
  getProfiles() {
    return this.instance({
      method: "GET",
      url: "/profiles",
    });
  }
  getUsers() {
    return this.instance({
      method: "GET",
      url: "/users",
    });
  }

  removeUser(uuid) {
    return this.instance({
      method: "DELETE",
      url: `/users/${uuid}`,
    });
  }

  addUser(data) {
    return this.instance({
      method: "POST",
      url: "/users",
      data,
    });
  }

  updateUser(data, uuid) {
    return this.instance({
      method: "PUT",
      url: `/users/${uuid}`,
      data,
    });
  }

  addRequest(data) {
    return this.instance({
      method: "POST",
      url: "/orders/solicitation",
      data,
    });
  }

  getOrder(orderId) {
    return this.instance({
      method: "GET",
      url: `/orders/${orderId}`,
    });
  }

  getUsersWithParams(region = null, unitId = null, profileId = null) {
    let url = "/users/dropdown?";
    let hasParams = false;
    if (region) {
      url = url + `region=${region}`;
      hasParams = true;
    }
    if (unitId) {
      if (hasParams) url = url + "&";
      url = url + `unitId=${unitId}`;
      hasParams = true;
    }
    if (profileId) {
      if (hasParams) url = url + "&";
      url = url + `profileId=${profileId}`;
    }

    return this.instance({
      method: "GET",
      url: url,
    });
  }

  getRegions() {
    return this.instance({
      method: "GET",
      url: `/units/regions`,
    });
  }

  getOrders(
    onlySolicitations = false,
    myUnit = false,
    myMakeUnit = false,
    mine = false,
    code = "",
    phaseId = ""
  ) {
    return this.instance({
      method: "GET",
      url: `/orders?onlySolicitations=${onlySolicitations}&myUnit=${myUnit}&myMakeUnit=${myMakeUnit}&mine=${mine}&code=${code}&phaseId=${phaseId}`,
    });
  }

  addOrder(data) {
    return this.instance({
      method: "POST",
      url: "/orders",
      data,
    });
  }

  getApplications() {
    return this.instance({
      method: "GET",
      url: "/applications",
    });
  }

  getClassifications() {
    return this.instance({
      method: "GET",
      url: "/classifications",
    });
  }

  getAreas() {
    return this.instance({
      method: "GET",
      url: "/areas/dropdown",
    });
  }

  getSubAreas(areaId) {
    return this.instance({
      method: "GET",
      url: `/areas/dropdown/${areaId}`,
    });
  }

  getMaterials() {
    return this.instance({
      method: "GET",
      url: "/materials",
    });
  }

  getEquipmentsByArea(areaId) {
    return this.instance({
      method: "GET",
      url: `/areas/${areaId}/equipments`,
    });
  }

  getPhases() {
    return this.instance({
      method: "GET",
      url: "/phases",
    });
  }

  updatePhase(uuid, data) {
    return this.instance({
      method: "PATCH",
      url: `/orders/${uuid}/phase`,
      data,
    });
  }

  addProjectImage(orderId, data) {
    return this.instance({
      method: "PATCH",
      url: `/projects/${orderId}/upload`,
      data,
    });
  }

  getProjects(code = "", inova = false, myMakeUnit = false) {
    return this.instance({
      method: "GET",
      url: `/projects?code=${code}&inova=${inova}&myMakeUnit=${myMakeUnit}`,
    });
  }

  getKanBan(filters) {
    let url = "?";
    let hasFilter = false;
    if (filters.requestUnitId) {
      url = url + `requestUnitId=${filters.requestUnitId}`;
      hasFilter = true;
    }

    if (filters.makeUnitId) {
      if (hasFilter) url = url + "&";
      url = url + `makeUnitId=${filters.makeUnitId}`;
      hasFilter = true;
    }

    if (filters.region) {
      if (hasFilter) url = url + "&";
      url = url + `region=${filters.region}`;
      hasFilter = true;
    }

    if (filters.classificationId) {
      if (hasFilter) url = url + "&";
      url = url + `classificationId=${filters.classificationId}`;
      hasFilter = true;
    }

    if (filters.designerId) {
      if (hasFilter) url = url + "&";
      url = url + `designerId=${filters.designerId}`;
      hasFilter = true;
    }

    if (filters.createdAtFrom) {
      if (hasFilter) url = url + "&";
      url = url + `createdAtFrom=${filters.createdAtFrom}`;
      hasFilter = true;
    }

    if (filters.createdAtTo) {
      if (hasFilter) url = url + "&";
      url = url + `createdAtTo=${filters.createdAtTo}`;
    }

    return this.instance({
      method: "GET",
      url: `/kanban${url}`,
    });
  }

  updateProject(data, uuid, puuid) {
    return this.instance({
      method: "PATCH",
      url: `/orders/${uuid}/project/${puuid}`,
      data,
    });
  }
}
